.productContainer {
	/* display: flex; */
	/* flex-direction: column;
	justify-items: flex-start; */
	display: flow;
	flex-direction: column;
	height: 100%;
}
.textfields {
	display: flex;
	flex-direction: column;
	justify-items: flex-start;
	height: 100%;
}
/* 
.textfields {
	display: flex;
	flex-direction: column;
	width: 100%;
}

/* .productContainer > div {
	flex: 1;
} */

/* .productContainer .buttons {
	max-height: 4em;
	min-height: 4em;
	padding: 0 2em;
}

.productContainer .buttons > div {
	margin-right: 1em;
} */

/* .top {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: start;
}

.right {
	width: 50%;
	height: 80%;
} */
.top {
	width: 100%;
	display: flex;
	overflow: auto;
}
.right {
	display: flex;
	flex-direction: column;
	flex: 1;
	margin: 0 auto;
	height: 100%;
	background: 'red';
}
h4 {
	margin-bottom: 0;
}

.validoo {
	padding: 1em;
	flex: 0;
	text-align: center;
}
