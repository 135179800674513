.sortIcon {
	cursor: pointer;
	/*path: {
    fill: rgb(158, 158, 158) !important;
    pointer-events: none;
  }*/
}

.tableHeader {
	display: flex;
	align-items: center;
}

.tableContainer {
	overflow: inherit;
}

.tableContainer .image {
	height: 4em;
	max-height: 4em;
	min-height: 4em;
}

.tooltipHoverable {
	pointer-events: auto !important;
}

.tooltipHoverable:hover {
	visibility: visible !important;
	opacity: 1 !important;
}

.sortTableCheckbox > div {
	justify-content: center;
}

.custom {
	display: inline-block;
	cursor: pointer;
}
