.approot {
	display: flex;
	flex-direction: row;
	font-family: 'Montserrat', sans-serif;
}

.view {
	overflow: none;
	/* height: 100%; */
}

.viewcontainer {
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 1 1;
	top: 60px;
	padding: 2%;
	overflow: none;
	height: calc(100vh - 168px);
}

.appbar {
	height: 4em !important;
	min-height: 4em !important;
	max-height: 4em !important;
}

.sidenav {
	width: 256px;
}

table tr:hover {
	background-color: #888583;
	cursor: pointer;
}
.tooltip {
	position: relative;
	display: inline-block;
	border-bottom: 1px dotted black;
	z-index: 1000;
}
