.productSearch {
	display: flex;
	flex-direction: row;
	min-width: 40%;
	max-width: 50%;
	height: 100%;
}
.filter {
	display: flex;
	align-items: baseline;
	flex-direction: column;
}

.filter .newProduct {
	min-width: 10em !important;
	margin-left: 2em !important;
}

.results {
	display: flex;
	flex: 1;
	flex-direction: column;
	margin-left: 3em;
}
.tree {
	width: auto;
}
