.storeList .compareContainer {
	flex: 1;
	flex-wrap: wrap;
	display: flex;
	align-items: center !important;
}

.storeList .compareContainer > div {
	margin: 0 1em;
}

.storeList {
	display: flex;
	flex-direction: row-reverse;
	align-items: center !important;
	flex-wrap: wrap;
}

.actions {
	display: flex;
	flex-direction: row;
	align-items: center !important;
	flex-wrap: wrap;
	width: 100%;
	justify-content: space-between;
}
