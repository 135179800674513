.crawlInfoContainer {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.crawlInfoContainer .top {
	padding: 2em;
	display: flex;
	flex-direction: row;
	height: 100%;
}

/* .top > div {
	flex-direction: column;
	display: flex;
	padding-right: 2em;
} */

.crawlInfoContainer .top .inputLabel {
	display: block;
	font-size: 12px;
	pointer-events: none;
	user-select: none;
	color: rgba(0, 0, 0, 0.3);
	margin-bottom: -1em;
	margin-top: 1em;
}

.crawlInfoContainer pre {
	font-size: 0.8em;
}
