.storeEditContainer {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	height: 100%;
}

.top {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

/* .storeEditContainer .container {
	padding: 2em;
	overflow: auto;
	height: 100%;
}

.storeEditContainer > div {
	flex: 1;
}

.storeEditContainer .top {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.storeEditContainer .top > div {
	flex-direction: column;
	display: flex;
	padding-right: 2em;
}

.storeEditContainer .top .inputLabel {
	display: block;
	font-size: 12px;
	pointer-events: none;
	user-select: none;
	color: rgba(0, 0, 0, 0.3);
	margin-bottom: -1em;
	margin-top: 1em;
}

.storeEditContainer .color {
	width: 1em;
	height: 1em;
	display: block;
	float: right;
	margin-top: 1em;
	margin-right: 1em;
}

.storeEditContainer .visible {
	max-height: 1em;
	max-width: 1em;
	display: block;
	float: right;
	margin-top: 1em;
	margin-right: 1em;
	margin-bottom: 1em;
}

.storeEditContainer input {
	min-width: 20em;
}

.inputLabelCheckbox {
	font-size: 12px;
	user-select: none;
	color: rgba(0, 0, 0, 0.3);
	margin-bottom: -1em;
} */
