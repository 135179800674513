.textfields {
	display: flex;
	flex-direction: column;
	margin-bottom: 1em;
	max-width: 30em;
	width: 100%;
	/* min-width: 20em; */
	padding-right: 2em;
}

.searchProducts {
	display: flex;
	flex-direction: column;
	margin-bottom: 1em;
	padding-left: 3em;
}

.top {
	display: flex;
	flex-direction: column;
	height: 100%;
}

/* .textfields > div {
	display: block;
} */

.productContainer {
	height: 100%;
	width: 100%;
}
/* 
.productContainer {
	flex: 1;
} */

/* .productContainer .top {
	flex: 1 1;
} */

h4 {
	margin-bottom: 0;
}

.productContainer .validoo {
	padding: 1em;
	flex: 0;
	text-align: center;
}

/* .buttons > button{
  margin-right: 1em;
  margin-top: .5em;
} */
