.sortButton div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

button .sortButton {
  min-width: 88px;
  color: rgba(0, 0, 0, 0.87);
}
