.textfields {
	display: flex;
	flex-direction: column;
}

.textfields > div {
	display: block;
}

.productContainer {
	display: flex;
	flex-direction: column;
	overflow: none;
	height: 100%;
}

.top {
	display: flex;

	/* flex-wrap: wrap; */
	height: 90%;
}

.productContainer .top .right {
	min-width: 20em;
	max-width: 30em;
	display: flex;
	flex-direction: column;
	overflow: auto;
	flex: 1;
}

.aiSearchWrapper {
	min-width: 20em;
	max-width: 25em;
	display: flex;
	flex-direction: column;
	overflow: auto;
	flex: 1;
	background: green;
}

h4 {
	margin-bottom: 0;
}

.productContainer .validoo {
	padding: 1em;
	flex: 0;
	text-align: center;
}
