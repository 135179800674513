.importContainer {
	flex: 1;
	display: flex;
	align-items: flex-start;
	padding: 1em;
	flex-direction: column;
}

.storeText {
	font-weight: bold;
	font-size: 1.1em;
}

.validationError {
	color: #ff2600;
	font-weight: bold;
}

.uploadContainer {
	display: flex;
}

.loadingIcon,
.cancelButton,
.importButton {
	margin-left: 1em;
}

.executedText {
	font-weight: bold;
}
