.brandListHolder {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-around;
	padding: 0;
	margin: 0;
	list-style: none;
}

.test {
	display: flex;
}

.lock {
	width: auto;
	align-self: center;
	padding-left: 2%;
}
