.sidenav > div {
	/* position: initial !important; */
	position: absolute !important;
	top: 60px;
	width: 260px;
}

.sidenav .MuiListItem-root {
	cursor: pointer;
}

.navItem:hover {
	cursor: pointer;
	background-color: #d5d4d2;
}

.active {
	background-color: #d5d4d2;
}

a {
	text-decoration: none;
	color: rgba(0, 0, 0, 0.87);
	font-size: 10px;
}
