.viewContainer {
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.subheader {
	padding-top: 1em;
	padding-right: 1em;
}

.bagList {
	padding: 0 0 0 1em;
	list-style: none;
}
