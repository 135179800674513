.variations {
	width: 100%;
}

/* select{
  font-size: 16px;
  line-height: 24px;
  width: 256px;
  height: 48px;
  display: inline-block;
  position: relative;
  background-color: transparent;
  font-family: Roboto, sans-serif;
  transition: height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  cursor: auto;
  border-width: 1px;
  border-style: solid;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
  border-image: initial;
  border-radius: 2px;
}

select>option{
  color: rgba(0, 0, 0, 0.87);
  background-color: rgb(255, 255, 255);
  transition: transform 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-shadow: rgb(0 0 0 / 12%) 0px 1px 6px, rgb(0 0 0 / 12%) 0px 1px 4px;
  border-radius: 2px;
  position: fixed;
  z-index: 2100;
  opacity: 1;
  transform: scaleY(1);
  transform-origin: left top;
  max-height: 1297px;
} */
