.productBagContainer {
	width: 100%;
	height: 100%;
	overflow: auto;
}

.top {
	padding: 2em;
	display: flex;
	flex-direction: column;
	width: 97%;
}
.bag {
	display: flex;
	height: auto;
	width: 100%;
	padding-bottom: 1em;
	justify-content: space-between;
}

.lower {
	display: flex;
	height: auto;
	width: 100%;
	padding-top: 1em;
	gap: 1em;
}

/* .bag {
	display: inline-flex;
	height: 7%;
	width: 100%;
	padding: 1em;
	background: lightgreen;
	flex-direction: column;
} */
/* .bagInfoContainer {
	display: flex;
	align-content: center;

	align-items: center;
} */

/* .textfields {
	display: flex;
	flex-direction: column;
} */

/* .test {
	display: inline-flex;
	justify-content: space-between;
	width: 100%;
} */

.searchForProductBag {
	width: 30%;
	padding: 1em;
	height: '80%';
	justify-content: end;
}
