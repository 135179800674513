.unmatchedContainer {
	padding: 0.5em 1em;
}

/* .unmatchedContainer {
  display: flex;
  flex-direction: column;
  padding:.5em 1em;
}

.unmatchedContainer .filter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 1em 0;
}
.unmatchedContainer .filter > div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.unmatchedContainer .filter .textfields {
  flex: 1 0 60%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.unmatchedContainer .filter .textfields > div {
  margin: 0 1em;
} */
