/* .noResults {
	margin: 1em 2em;
}

.progress {
	margin: 1em;
}

.productList {
	flex-direction: column;
	overflow: auto;
	padding-bottom: 1%;
}
img {
	flex-basis: content;
	top: 0px;
} */

/* .productAvatar {
	width: auto;
	height: auto;
	object-fit: contain;
	margin: '0 auto';
	overflow: 'hidden';
} */

/* img {
	width: 100px;
	height: 100px;
	text-align: center;
	object-fit: contain;
	color: transparent;
	text-indent: 10000px;
} */

.productImageHolder {
	width: auto;
	/* height: auto; */
	overflow: hidden;
	display: flex;
	padding-right: 1em;
	position: relative;
	justify-content: center;
}

img {
	display: flex;
	width: 80px;
	height: 80px;
	/* justify-content: end; */
	object-fit: contain;
	overflow: hidden;
}

/*

	width: '80px';
	height: 'auto';
	object-fit: 'contain';
	overflow: 'hidden';
	align-content: 'start';
	display: 'flex';
	justify-content: 'center';
	*/
