.crawlerListContainer {
	padding: 2em;
}

.crawlerListContainer .top .stats {
	float: right;
	flex: 1;
	text-align: right;
	padding: 0 1em;
	font-size: 0.9em;
	justify-content: end;
	align-items: end;
	gap: 1em;
}

.crawlerListContainer .top {
	display: flex;
	flex-wrap: wrap;
}
