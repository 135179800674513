html {
	-webkit-font-smoothing: antialiased;
}

body {
	font-family: 'Montserrat', sans-serif;
	line-height: 24px;
	padding: 0;
	margin: 0;
}

h2 {
	margin-top: 0;
}

h3 {
	margin-top: 0;
}

.padding {
	padding: 1em;
}

.flexSpaceBetween {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.flexColumn {
	display: flex;
	flex-direction: column;
}

.contextMenu {
	border-radius: 10px;
	border: #9d9898 1px solid;
	position: fixed;
	background: white;
	display: block;
	-webkit-box-shadow:
		0px 10px 13px -7px #000000,
		5px 5px 15px 5px rgba(0, 0, 0, 0);
	box-shadow:
		0px 10px 13px -7px #000000,
		5px 5px 15px 5px rgba(0, 0, 0, 0);
	z-index: 99999;
}

.contextMenu--option:active {
	color: #e9e9e9;
	background: linear-gradient(to top, #555, #444);
}
.contextMenu--option__disabled {
	color: #999;
	pointer-events: none;
}
.contextMenu--separator {
	width: 100%;
	height: 1px;
	background: #ccc;
	margin: 0 0 0 0;
}

.context-item {
	padding: 6px 50px 5px 10px;
	min-width: 160px;
	cursor: default;
	font-size: 15px;
	display: flex;
	list-style: none;
}

.context-item:hover {
	background: linear-gradient(to top, #555, #333);
	border-radius: 5px;
	color: white;
}

.contextMenu--option .context-icon {
	padding-right: 10px;
}

.buttons {
	display: contents;
	justify-content: end;
}

.buttons button {
	margin-right: 1em;
	margin-top: 0.5em;
}
