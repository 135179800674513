.storeList .compareContainer {
	flex: 1;
	display: flex;
	align-items: flex-end;
	display: inhe;
}

.storeList .compareContainer > div {
	margin: 0 1em;
}

.storeList .actions {
	display: flex;
	flex-direction: row-reverse;
	align-items: baseline;
	flex-wrap: wrap;
}
