.brands {
  width: 90%;
}

ul.pagination {
  display: inline-block;
  padding: 0;
  margin: 0;
}

ul.pagination li {
  display: inline;
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
}

.active {
  background-color: rgb(210, 41, 13) !important;
  color: white !important;
}

ul.pagination li:hover:not(.active) {
  background-color: red;
}

.search-holder {
  display: flex;
}

.search-holder span {
  padding-left: 30px;
}
