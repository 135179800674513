.homeContainer .progressAll {
	background-color: #c20016;
	width: 100%;
	border-radius: 5px;
	height: 1.6em;
	border: 1px solid #ccc;
	color: #fff;
	overflow: hidden;
	display: none;
}

.homeContainer .progressContainer {
	padding: 0.5em;
}

.homeContainer .progressAll .label {
	text-align: center;
}

.homeContainer .progressDone {
	background-color: #f5f5f5;
	border-radius: 0 5px 5px 0;
	border-left: 1px solid #ccc;
	height: 1.6em;
	float: right;
	text-align: center;
	color: #000;
	overflow: hidden;
}

.homeContainer {
	padding: 2em;
}

.homeContainer h3 {
	margin-bottom: 0;
}
