.clientEditContainer {
	display: flex;
	flex-direction: column;
}

.top {
	overflow: none;
	height: 90vh;
}

.fields {
	display: flex;
	flex-direction: column;
	max-width: 40em;
	gap: 1em;
}
