.crawlerEditContainer {
	display: flex;
	flex-direction: column;
	height: 100%;
}

/* .crawlerEditContainer .container {
	padding: 2em;
	overflow: scroll;
	height: 90vh;
} */

/* .crawlerEditContainer > div {
	flex: 1;
} */

.crawlerEditContainer .top {
	padding: 2em;
	display: flex;
	flex-direction: column;
	height: 100%;
}
